// Css
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import './App.css'

// Light
// import 'primereact/resources/themes/tailwind-light/theme.css'
import 'primereact/resources/themes/soho-light/theme.css';

// Dark
// import 'primereact/resources/themes/lara-dark-blue/theme.css'
// import 'primereact/resources/themes/lara-dark-indigo/theme.css'

import { AxiosError } from 'axios';
import { Avatar } from 'primereact/avatar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import ApiService from './api-service';
import Notifications from './components/Notifications';
import { HistoricalRevisionsReportPage } from './pages/admin/historical-revisions-report';
import { ReportsPlatePage } from './pages/admin/reports-plate';
import { UsersPage } from './pages/admin/users';
import { DashboardPage } from './pages/dashboard';
import { HistoricalRevisionsPage } from './pages/historical-revisions';
import { LoginPage } from './pages/login';
import { setTokens } from './redux/actions/auth-actions';
import { NotificationType, addNotification } from './redux/actions/notification-actions';
import { authSelector, breadcrumbSelector } from './redux/selectors/selectors';
import { UserPage } from './pages/admin/user';
import { Button } from 'primereact/button';
import { CarPage } from './pages/car';
// import Chatbot from './components/Chatbot';

function App() {
  const naviagate = useNavigate();
  const dispatch = useDispatch();
  const { breadcrumbs } = useSelector(breadcrumbSelector);
  const auth = useSelector(authSelector);
  const navigate = useNavigate();

  const [sidebarVisible, setSidebarVisible] = useState(false);

  useEffect(() => {
    if (sidebarVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [sidebarVisible])

  // const { changeTheme } = useContext(PrimeReactContext);

  // useEffect(() => {
  //   if(changeTheme !== undefined){
  //     changeTheme("", "", "",() => {

  //     })
  //   }
  // }, [changeTheme, useContext])


  useEffect(() => {
    const interval = setInterval(() => {
      if (auth.payload !== undefined) {
        const now = Math.floor(Date.now() / 1000);

        const timeLeft = auth.payload.exp - now;
        const hours = Math.floor(timeLeft / 3600);
        const minutes = Math.floor((timeLeft % 3600) / 60);
        console.log(`Il token scadrà tra ${hours} ore e ${minutes} minuti`);

        if (now >= auth.payload.exp) {
          // Token scaduto, refresharlo
          ApiService.refreshToken({
            refreshToken: auth.refreshToken
          }).then(response => {
            dispatch(
              setTokens(response.data.access_token, response.data.refresh_token)
            )
            dispatch(
              addNotification('Sessione refreshata!', NotificationType.INFO)
            );
          }).catch((err: AxiosError) => {
            dispatch(
              setTokens("", "")
            )

            dispatch(
              addNotification('Sessione scaduta, rieffettua il login!', NotificationType.WARNING)
            )

            naviagate("/login")
          })
        }
      }
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch, auth]);

  const menuItems: MenuItem[] | undefined = [
    {
      command: () => {
        // toast.current.show({ severity: 'info', summary: 'Info', detail: 'Item Selected', life: 3000 });
      },
      template: (item, options) => {
        return (
          <button onClick={(e) => navigate('/user')} className={classNames(options.className, 'w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround')}>
            <Avatar style={{
              width: 40,
              height: 40
            }}  image="/logo192.png" className="mr-2" shape="circle" />
            <div className="flex flex-column align">
              <span className="font-bold">{auth?.payload?.email.split('@')[0] || 'No user'}</span>
              <span className="text-sm">{auth?.payload?.isAdmin || false ? 'Administrator' : 'User'}</span>
            </div>
          </button>
        );
      }
    },
    {
      separator: true
    },
    auth?.payload?.isAdmin || false ? {
      label: 'Admin',
      items: [
        {
          label: 'Utenti',
          icon: 'pi pi-users',
          command: () => {
            naviagate("/admin/users")
            setSidebarVisible(false);
          }
        },
        {
          label: 'Plate Reports',
          icon: 'pi pi-chart-bar',
          command: () => {
            naviagate("/admin/reports/plate")
            setSidebarVisible(false);
          }
        },
        {
          label: 'Mobile Reports',
          icon: 'pi pi-chart-line'
        },
        {
          label: 'Historical Revisions',
          icon: 'pi pi-chart-pie',
          command: () => {
            naviagate("/admin/reports/historical-revisions")
            setSidebarVisible(false);
          }
        }
      ]
    } : {
      separator: true
    },
    {
      label: 'Visure',
      items: [
        {
          label: 'Auto',
          icon: 'pi pi-car',
          disabled: auth?.accessToken == "",
          style: {
            cursor: auth?.accessToken == "" ? "not-allowed" : "auto"
          },
          command: () => {
            naviagate("/car")
            setSidebarVisible(false);
          }
        },
        {
          label: 'Moto',
          icon: 'pi pi-prime',
          disabled: auth?.accessToken == "",
          style: {
            cursor: auth?.accessToken == "" ? "not-allowed" : "auto"
          }
        },
        {
          label: 'Assicurazione',
          icon: 'pi pi-shield',
          disabled: auth?.accessToken == "",
          style: {
            cursor: auth?.accessToken == "" ? "not-allowed" : "auto"
          }
        },
        {
          label: 'Storico Revisioni',
          icon: 'pi pi-history',
          disabled: auth?.accessToken == "",
          style: {
            cursor: auth?.accessToken == "" ? "not-allowed" : "auto"
          },
          command: () => {
            naviagate("/historical-revisions")
            setSidebarVisible(false);
          }
        },
      ]
    },
    {
      label: 'Profilo',
      items: [
        {
          label: 'Utente',
          icon: 'pi pi-cog',
          disabled: auth?.accessToken == "",
          style: {
            cursor: auth?.accessToken == "" ? "not-allowed" : "auto"
          },
          command: () => {
            navigate('/user')
            setSidebarVisible(false);
          }
        },
        auth?.accessToken == "" ? {
          label: 'Login',
          icon: 'pi pi-sign-in',
          style: {

          },
          command: () => {
            naviagate("/login")
            setSidebarVisible(false);
          }
        } : {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          command: () => {
            dispatch(
              setTokens("", "")
            )

            dispatch(
              addNotification('Logout effettuato correttamente!', NotificationType.WARNING)
            )

            naviagate("/login")
            setSidebarVisible(false);
          }
        }
      ]
    },
    // {
    //   label: "Chiudi",
    //   template: (item, options) => {
    //     return (
    //       <div style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}>
    //         <Button severity="warning" text><i style={{
    //           fontSize: 20
    //         }} className="pi pi-times-circle"></i></Button>
    //       </div>
    //     )
    //   }
    // }
  ];

  const home = {
    icon: 'pi pi-home',
    command: () => {
      navigate("/dashboard")
    }
  };

  const toast = useRef(null);

  const isMobile = useMediaQuery({ query: '(max-width: 1100px)' });

  return (
    <div style={{
      display: "flex",
      gap: 10,
      minHeight: "100vh",
    }}>

      {/* <Chatbot /> */}
      {!isMobile || sidebarVisible ? <div className="card flex">
        <Toast ref={toast} />
        <Menu style={{
          position: isMobile ? "fixed" : "relative",
          zIndex: 1000,
          width: 250,
          borderRadius: 10,
          height: "100%",
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)",
          backgroundColor: "rgb(255, 255, 255)"
        }} model={menuItems} />

        {isMobile && <div onClick={() => {
          setSidebarVisible(false);
        }} style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          marginTop: -8,
          marginLeft: -10,
          zIndex: 999,
        }}>

        </div>}
      </div> : <></>}

      <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10
      }}>

        <div style={{
          display: "flex",
          alignItems: "center",
          gap: 0,
          paddingLeft: isMobile ? 18 : 0,
          borderRadius: 10,
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)",
          backgroundColor: "rgb(255, 255, 255)"
        }}>
          {isMobile && <i
            onClick={() => setSidebarVisible(!sidebarVisible)}
            style={{
              cursor: "pointer",
              color: 'var(--primary)',
            }} className="pi pi-bars"></i>}

          <BreadCrumb style={{
            width: "100%",
            border: "none",
            // height: 50,
            backgroundColor: "rgb(255, 255, 255)"
          }} model={breadcrumbs} home={home} />
        </div>


        <div style={{
          backgroundColor: "#ffffff",
          border: "1px solid #dee2e6",
          borderRadius: 10,
          width: "100%",
          height: "100%",
          paddingLeft: 20, paddingRight: 20,
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)"
        }}>
          <Routes>
            <Route path="/*" element={
              <Navigate
                to={{
                  pathname: '/dashboard',
                }}
              />
            }
            />
            <Route
              path='/dashboard'
              element={
                <DashboardPage />
              }>
            </Route>
            <Route
              path='/login'
              element={
                <LoginPage />
              }>
            </Route>

            <Route
              path='/admin/reports/plate'
              element={
                <ReportsPlatePage />
              }>
            </Route>

            <Route
              path='/admin/reports/historical-revisions'
              element={
                <HistoricalRevisionsReportPage />
              }>
            </Route>

            <Route
              path='/admin/users'
              element={
                <UsersPage />
              }>
            </Route>

            <Route
              path='/historical-revisions'
              element={
                <HistoricalRevisionsPage />
              }>
            </Route>

            <Route
              path='/user'
              element={
                <UserPage />
              }>
            </Route>

            <Route
              path='/car'
              element={
                <CarPage />
              }>
            </Route>
          </Routes>

        </div>
      </div>

      <Notifications />
    </div>
  );
}

export default App;