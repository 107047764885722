import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { FC, ReactNode, useEffect, useState } from "react";

interface AppAppleMobileModalProps { }

const APPLE_DONT_SHOW_AGAIN = "APPLE_DONT_SHOW_AGAIN";

const isIphone = (): boolean => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // @ts-ignore
    return /iPhone|iPod/.test(userAgent) && !window.MSStream;
};

export const AppAppleMobileModal: FC<AppAppleMobileModalProps> = ({ }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState<boolean>(false);

    useEffect(() => {
        // Controlla se è un iPhone e se l'utente ha già scelto di non mostrare la modale
        if (isIphone() && localStorage.getItem(APPLE_DONT_SHOW_AGAIN) !== "true") {
            setIsVisible(true);
        }
    }, []);

    const handleClose = () => {
        const dontShowAgain = localStorage.getItem(APPLE_DONT_SHOW_AGAIN) === "true";
        setIsVisible(false);
        if (!dontShowAgain) {
            setIsConfirmDialogVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const handleConfirm = () => {
        localStorage.setItem(APPLE_DONT_SHOW_AGAIN, "true");
        setIsConfirmDialogVisible(false);
        setIsVisible(false);
    };

    const handleCancel = () => {
        setIsConfirmDialogVisible(false);
        setIsVisible(false);
    };

    const Modal: FC<{ visible: boolean; onClose: () => void; children: ReactNode }> = ({ visible, onClose, children }) => {
        if (!visible) return null;

        return (
            <div style={modalOverlayStyle}>
                <div style={modalContentStyle}>
                    <i onClick={onClose} style={closeButtonStyle} className="pi pi-times"></i>
                    {children}
                </div>
            </div>
        );
    };

    const modalOverlayStyle: React.CSSProperties = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
    };

    const modalContentStyle: React.CSSProperties = {
        backgroundColor: "#1E1E25",
        borderRadius: "8px",
        padding: "1rem",
        position: "relative",
        width: "90%",
        maxWidth: "600px",
        color: "white",
    };

    const closeButtonStyle: React.CSSProperties = {
        position: "absolute",
        top: "10px",
        right: "10px",
        background: "none",
        border: "none",
        color: "white",
        fontSize: "1.5rem",
        cursor: "pointer",
        zIndex: 1000,
    };

    return (
        <>
            <Modal visible={isVisible} onClose={handleClose}>
                <div style={{ width: "100%", height: "100%", position: "relative" }}>
                    <video
                        onClick={() => window.open("https://apps.apple.com/it/app/targa-site/id6475402711")}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                        autoPlay
                        loop
                        muted
                        playsInline 
                        controls={false}
                    >
                        <source src={"/video/app_apple.mp4"} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
            </Modal>

            <Dialog
                visible={isConfirmDialogVisible}
                onHide={() => setIsConfirmDialogVisible(false)}
                header="Chiudi"
                footer={
                    <div className="mt-4">
                        <Button label="Non mostrare più" onClick={handleConfirm} className="p-button-danger" />
                        <Button label="Chiudi" onClick={handleCancel} className="p-button-primary" />
                    </div>
                }
            >
                <p>Non vuoi più vedere questa modale in futuro?</p>
            </Dialog>
        </>
    );
};